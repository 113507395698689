<template>
  <div>
    <Header />
    <div class="md:h-480 h-96 w-full bg-black bg-opacity-30 relative">
      <img
        src="../assets/hero-about.png"
        alt="Hero About"
        class="h-full w-full absolute object-cover mix-blend-overlay -z-10"
      />
      <div
        class="h-full w-full flex justify-center items-center text-center text-white font-bold"
      >
        <h1 class="md:text-7xl text-4xl">User Agreement</h1>
      </div>
    </div>
    <div class="md:mx-20 md:my-16 p-8">
      <div class="flex flex-col md:gap-16 gap-8">
        <div class="flex flex-col md:gap-8 gap-4">
          <p class="font-semibold md:text-2xl text-lg text-center">
            SYARAT DAN KETENTUAN PENGGUNAAN
          </p>
          <p class="text-justify text-sm md:text-lg text-grey-2 italic">
            Syarat penggunaan dibawah ini harus dibaca sebelum menggunakan
            dan/atau mengakses aplikasi atau situs ini. Penggunaan aplikasi atau
            situs ini menunjukkan penerimaan dan kepatuhan terhadap syarat
            penggunaan dibawah ini.
          </p>
          <p class="text-justify text-sm md:text-lg text-grey-2">
            Aplikasi Tripwe dan/atau situs
            <a href="https://www.tripwe.com" class="text-green-1"
              >www.tripwe.com</a
            >
            dikelola oleh PT Tripedia Global Adventura dan perusahaan
            afiliasinya (<strong>“kami”</strong>,
            <strong>“milik kami”</strong> atau <strong>“Tripwe”</strong>).
          </p>
          <p class="text-justify text-sm md:text-lg text-grey-2">
            Dengan mengakses dan/atau menggunakan bagian mana pun dari aplikasi
            dan/atau situs
            <a href="https://www.tripwe.com" class="text-green-1"
              >www.tripwe.com</a
            >, Pengguna mengakui bahwa Pengguna telah membaca dan memahami, dan
            menyetujui situs syarat penggunaan dan ketentuan lainnya dalam
            aplikasi dan/atau situs
            <a href="https://www.tripwe.com" class="text-green-1"
              >www.tripwe.com</a
            >
            (<strong>“Perjanjian Pengguna”</strong>) dan ketentuan lainnya
            sehubungan dengan Tripwe sebagaimana dimaksud dalam Perjanjian
            Penggunaan ini.
          </p>
          <p class="text-justify text-sm md:text-lg text-grey-2">
            Jika Pengguna tidak setuju untuk terikat oleh Perjanjian Pengguna
            ini, Pengguna tidak boleh mengakses atau menggunakan bagian mana pun
            dari Tripwe. Ketentuan ini merupakan perjanjian hukum yang mengikat
            antara Pengguna sebagai pengguna individu dan Tripwe.
          </p>
        </div>
        <div class="flex flex-col gap-4">
          <p class="font-semibold md:text-2xl text-lg text-center">DEFINISI</p>
          <ul
            class="list-decimal text-justify text-sm md:text-lg text-grey-2 md:ml-10 ml-4"
          >
            <li>
              Situs Tripwe adalah
              <a href="https://www.tripwe.com" class="text-green-1"
                >www.tripwe.com</a
              >
            </li>
            <li>
              Aplikasi Tripwe adalah aplikasi dengan nama ‘Tripwe’ yang tersedia
              dan dapat diunduh secara umum di Google Play Store yang dikelola
              oleh oleh PT Tripedia Global Adventura dan perusahaan afiliasinya.
            </li>
            <li>
              Pengguna adalah pihak yang menggunakan layanan Situs Tripwe
              dan/atau Aplikasi Tripwe yang harus setidaknya berusia 18 (delapan
              belas) tahun untuk menggunakan Situs Tripwe.
            </li>
            <li>
              Barang adalah benda yang berwujud atau memiliki fisik barang yang
              termasuk dalam cakupan ruang lingkup Tripwe yang disediakan oleh
              Vendor dalam Situs Tripwe dan/atau Aplikasi Tripwe. Apabila
              diperlukan, Barang juga dapat disediakan langsung oleh pihak
              Tripwe.
            </li>
            <li>
              Jasa adalah jasa yang diberikan oleh Vendor yang disediakan dalam
              Situs Tripwe dan/atau Aplikasi Tripwe.
            </li>
            <li>
              Vendor adalah pihak ketiga yang menyediakan layanan jasa atau
              pengelola wisata transportasi air atau darat atau penyedia layanan
              lain yang bekerjasama dengan Tripwe.
            </li>
          </ul>
        </div>
        <div class="flex flex-col gap-4">
          <p class="font-semibold md:text-2xl text-lg text-center">
            RUANG LINGKUP JASA KAMI
          </p>
          <p class="text-justify text-sm md:text-lg text-grey-2">
            Melalui Aplikasi Tripwe dan/atau Situs Tripwe ini, kami menyediakan
            platform online sehingga Pengguna dapat menelusuri berbagai jenis
            alat transportasi wisata baik darat dan air (sebagaimana berlaku),
            serta membuat reservasi, pemesanan dan/atau pembelian
            (<strong>“Layanan”</strong>). Pengguna dapat membuat pesanan,
            membeli barang, meletakkan pesanan transportasi wisata air,
            transportasi wisata darat, paket perjalanan wisata atau layanan
            lainnya kepada Vendor Kami pada Aplikasi Tripwe dan/atau Situs
            Tripwe. Kami akan memberikan konfirmasi pemesanan atau pembelian
            melalui konfirmasi email, via Aplikasi Tripwe dan/atau Situs Tripwe.
            Kami berhak menolak pemesanan dan/atau pembelian sebagaimana diatur
            dalam Perjanjian Pengguna ini.
          </p>
        </div>
        <div class="flex flex-col gap-4">
          <p class="font-semibold md:text-2xl text-lg text-center">
            AKUN PENGGUNA
          </p>
          <ul
            class="list-decimal text-justify text-sm md:text-lg text-grey-2 md:ml-10 ml-4"
          >
            <li>
              Pengguna dengan ini menyatakan bahwa Pengguna adalah orang yang
              cakap dan mampu mengikatkan diri dalam sebuah perjanjian yang sah
              menurut hukum.
            </li>
            <li>Tripwe tidak memungut biaya pendaftaran kepada Pengguna.</li>
            <li>
              Pengguna memahami bahwa satu nomor telepon dan satu alamat e-mail
              hanya dapat digunakan untuk mendaftar satu akun Pengguna Tripwe.
            </li>
            <li>
              Pengguna harus membuat akun untuk dapat mengggunakan Aplikasi
              Tripwe dan/atau Situs Tripwe. Kami akan mengumpulkan dan memproses
              data pribadi Pengguna, seperti nama, alamat e-mail, nomor telefon
              ketika Pengguna mendaftar untuk membuat akun. Pengguna harus
              memberikan kami informasi yang terbaru, lengkap, dan akurat dan
              setuju untuk memberikan kami bukti atau identitas apapun yang kami
              mungkin syaratkan. Kami hanya akan mengumpulkan serta menggunakan
              data tersebut untuk kepentingan administrasi kami dan tidak akan
              mengumbar data pribadi Pengguna sehubungan dengan kebijakan
              privasi kami, <strong>tersedia disini</strong>.
            </li>
            <li>
              Hanya Pengguna yang dapat menggunakan akun Pengguna sendiri dan
              Pengguna menyatakan dan menjamin bahwa Pengguna tidak akan
              mengizinkan pihak lain mana pun untuk menggunakan identitas atau
              akun Pengguna dengan alasan apapun, kecuali diizinkan oleh Tripwe.
            </li>
            <li>
              Pengguna harus menjaga keamanan dan kerahasiaan kata sandi akun
              Pengguna dan setiap identifikasi yang kami berikan kepada
              Pengguna. Dalam hal pengungkapan kata sandi Pengguna, dengan cara
              apa pun yang mengakibatkan penggunaan akun atau identitas Pengguna
              secara tidak sah, pesanan yang diterima dari penggunaan yang tidak
              sah tersebut akan tetap dianggap sebagai pesanan yang sah dan kami
              akan memproses pesanan tersebut. Tunduk pada hak yang mungkin
              Pengguna miliki secara hukum, Pengguna dengan ini menyatakan bahwa
              Tripwe tidak bertanggung jawab atas segala kerugian atau kerusakan
              yang timbul dari penyalahgunaan akun Pengguna sesuai dengan
              klausul ini.
            </li>
            <li>
              Tripwe memiliki hak prerogatif untuk melakukan pemblokiran
              sementara, penghapusan, atau menon-aktifkan akun Pengguna tanpa
              harus memberikan alasan kepada Pengguna. Alasan untuk melakukan
              pemblokiran sementara, penghapusan, atau menon-aktifkan akun
              Pengguna adalah termasuk namun tidak terbatas pada:
              <ol
                class="list-decimal text-justify text-sm md:text-lg text-grey-2 md:ml-10 ml-4"
              >
                <li>Melakukan pelanggaran atas perjanjian ini</li>
                <li>Melakukan aktivitas kriminal</li>
                <li>Melakukan pemesanan yang mencurigakan dan/atau</li>
                <li>
                  Masuk dalam daftar hitam atau buronan negara maupun organisasi
                  Internasional.
                </li>
              </ol>
            </li>
            <li>
              Pengguna dengan ini menyatakan bahwa Tripwe tidak bertanggung
              jawab atas kerugian ataupun kendala yang timbul atas
              penyalahgunaan akun Pengguna yang diakibatkan oleh kelalaian
              Pengguna, termasuk namun tidak terbatas pada menyetujui dan/atau
              memberikan akses masuk akun yang dikirimkan oleh Tripwe melalui
              pesan notifikasi kepada pihak lain melalui perangkat Pengguna,
              meminjamkan akun kepada pihak lain, maupun kelalaian Pengguna
              lainnya yang mengakibatkan kerugian ataupun kendala pada akun
              Pengguna.
            </li>
          </ul>
        </div>
        <div class="flex flex-col gap-4">
          <p class="font-semibold md:text-2xl text-lg text-center">
            TRANSAKSI PEMESANAN DAN/ATAU PEMBELIAN
          </p>
          <ul
            class="list-decimal text-justify text-sm md:text-lg text-grey-2 md:ml-10 ml-4"
          >
            <li>
              Pengguna wajib bertransaksi melalui prosedur transaksi yang telah
              ditetapkan oleh Tripwe.
            </li>
            <li>
              Pengguna memahami sepenuhnya dan menyetujui bahwa segala transaksi
              pemesanan dan/atau pembelian yang dilakukan Pengguna selain dari
              prosedur pemesanan, pembelian dan/atau pembayaran yang telah
              ditetapkan, merupakan tanggung jawab dari Pengguna.
            </li>
            <li>
              Saat melakukan pemesanan dan/atau pembelian Barang, Pengguna
              menyetujui bahwa:
              <ol
                class="list-decimal text-justify text-sm md:text-lg text-grey-2 md:ml-10 ml-4"
              >
                <li>
                  Pengguna bertanggung jawab untuk membaca, memahami, dan
                  menyetujui informasi, deskripsi atas keseluruhan Barang
                  dan/atau Jasa.
                </li>
                <li>
                  Pengguna mengakui bahwa warna sebenarnya dari produk
                  sebagaimana terlihat di Aplikasi Tripwe dan/atau Situs Tripwe
                  tergantung pada monitor komputer dan layar handphone Pengguna.
                  Tripwe akan melakukan upaya terbaik untuk memastikan warna
                  dalam foto yang ditampilkan dalam Aplikasi Tripwe dan/atau
                  Situs Tripwe muncul seakurat mungkin.
                </li>
              </ol>
            </li>
            <li>
              Pengguna disarankan untuk melakukan pemeriksaan kembali jumlah
              nominal pembayaran dengan jumlah tagihan yang tertera, apabila
              terdapat kekurangan pembayaran, Pengguna dapat melakukan
              pemberitahuan melalui e-mail Pengguna yang terdaftar dalam
              aplikasi guna melakukan pembayaran kembali.
            </li>
            <li>
              Pembayaran dengan metode pembayaran transfer bank sangat
              disarankan untuk mengunggah bukti pembayaran pada aplikasi Tripwe
              untuk mempermudah verifikasi.
            </li>
            <li>
              Pengguna memahami dan menyetujui bahwa masalah keterlambatan
              proses pembayaran dan biaya tambahan yang disebabkan karena adanya
              perbedaan bank yang Pengguna pergunakan dengan Bank Rekening Resmi
              Tripwe adalah tanggung jawab Pengguna secara pribadi.
            </li>
          </ul>
        </div>
        <div class="flex flex-col gap-4">
          <p class="font-semibold md:text-2xl text-lg text-center">
            PERMINTAAN KHUSUS
          </p>
          <ul
            class="list-decimal text-justify text-sm md:text-lg text-grey-2 md:ml-10 ml-4"
          >
            <li>
              Dalam melakukan pemesanan dan/atau pembelian, Pengguna dapat
              mengajukkan permintaan khusus terkait pemesanan dan/atau pembelian
              tersebut dengan menuliskannya pada bagian catatan pada saat hendak
              melakukan pemesanan tersebut. Pengguna memahami dan menyetujui
              bahwa pemenuhan dari permintaan khusus Pengguna adalah wewenang
              mutlak dari Tripwe, dan oleh karenanya pemenuhan dari permintaan
              khusus tersebut akan ditentukan berdasarkan ketersediaan dan
              kebijakan internal dari Tripwe, dan Pengguna memahami bahwa Tripwe
              mungkin membebankan biaya tambahan terhadap permintaan khusus
              tersebut.
            </li>
            <li>
              Sehubungan dengan perubahanan dalam pemesanan dan/atau pembelian,
              Pengguna memahami bahwa Tripwe memiliki wewenang penuh untuk
              mengabulkan permohonan dalam perubahan tersebut. Pengguna juga
              memahami bahwa pengajuan permohonan perubahan tersebut harus
              sesuai dengan syarat dan jangka waktu pengajuan yang sebagaimana
              tercantum pada halaman saat hendak melakukan pemesanan/dan atau
              pembelian tersebut.
            </li>
          </ul>
        </div>
        <div class="flex flex-col gap-4">
          <p class="font-semibold md:text-2xl text-lg text-center">
            SARAN PERJALANAN
          </p>
          <p class="text-justify text-sm md:text-lg text-grey-2">
            Dengan menampilkan layanan jasa atau destinasi tertentu, Tripwe
            tidak mengemukakan atau menjamin bahwa perjalanan wisata ke
            tempat-tempat tersebut bermanfaat atau bebas risiko dan Tripwe tidak
            akan dimintai pertanggungjawaban atas kerusakan atau kerugian yang
            mungkin diakibatkan dari perjalanan ke destinasi tersebut. Dalam
            situasi apa pun, Tripwe tidak akan bertanggung jawab atas insiden
            merugikan yang terjadi selama perjalanan. Pengguna secara pribadi
            bertanggung jawab untuk pemilihan perjalanan, rute dan destinasi
            perjalanan, untuk sepanjang perjalanan Pengguna.
          </p>
        </div>
        <div class="flex flex-col gap-4">
          <p class="font-semibold md:text-2xl text-lg text-center">
            PROSEDUR PEMBAYARAN
          </p>
          <ul
            class="list-decimal text-justify text-sm md:text-lg text-grey-2 md:ml-10 ml-4"
          >
            <li>
              Segala pembayaran diadakan dalam nilai dan mata uang Rupiah.
            </li>
            <li>Tripwe tidak memungut pajak atau retribusi apapun.</li>
            <li>
              Untuk semua bentuk pemesanan dan/atau pembelian, Pengguna harus
              melakukan pembayaran dalam batas waktu yang ditentukan. Jika
              pembayaran tidak dilakukan, maka Tripwe berhak membatalkan semua
              pemesanan dan/atau pembelian.
            </li>
            <li>
              Metode pembayaran termasuk tetapi tidak terbatas pada transfer
              bank, Tripwe Pay, kartu kredit, dan metode pembayaran lain yang
              tersedia di Tripwe. Untuk pembayaran melalui transfer bank,
              Pengguna harus memberikan struk transfer bank sebagai bukti
              pembayaran reservasi yang tercantum dalam email konfirmasi Tripwe.
              Pengguna harus mengikuti prosedur yang terdapat dalam email
              konfirmasi. Untuk pembayaran dengan kartu kredit, Pengguna harus
              mengikuti setiap prosedur yang dinyatakan oleh Tripwe dan/atau
              penyedia layanan pembayaran terkait.
            </li>
            <li>
              Metode pembayaran termasuk tetapi tidak terbatas pada transfer
              bank, Tripwe Pay, kartu kredit, dan metode pembayaran lain yang
              tersedia di Tripwe. Untuk pembayaran melalui transfer bank,
              Pengguna harus memberikan struk transfer bank sebagai bukti
              pembayaran reservasi yang tercantum dalam email konfirmasi Tripwe.
              Pengguna harus mengikuti prosedur yang terdapat dalam email
              konfirmasi. Untuk pembayaran dengan kartu kredit, Pengguna harus
              mengikuti setiap prosedur yang dinyatakan oleh Tripwe dan/atau
              penyedia layanan pembayaran terkait.
            </li>
          </ul>
        </div>
        <div class="flex flex-col gap-4">
          <p class="font-semibold md:text-2xl text-lg text-center">
            PEMBAYARAN DENGAN KARTU KREDIT & PENIPUAN
          </p>
          <ul
            class="list-decimal text-justify text-sm md:text-lg text-grey-2 md:ml-10 ml-4"
          >
            <li>
              Untuk pembayaran pemesanan yang ditempatkan melalui Aplikasi
              Tripwe dan/atau Situs Tripwe, kartu kredit Anda akan ditagih oleh
              Tripwe untuk harga penuh pada saat pemesanan dan konfirmasi dari
              pemesanan (setiap pengembalian yang mungkin diberikan akan
              tergantung pada ketentuan pemesanan yang ada). Pengguna harus
              memeriksa rincian pemesanan secara menyeluruh setiap saat sebelum
              Anda membuat pemesanan. Tripwe akan memproses setiap pengembalian,
              sebagaimana berlaku, dalam jangka waktu yang wajar.
            </li>
            <li>
              Dalam hal adanya penipuan kartu kredit atau penyalahgunaan atas
              kartu kredit Pengguna oleh pihak ketiga, Pengguna harus segera
              menghubungi bank atau penerbit kartu anda setelah anda mengetahui
              adanya penyalahgunaan tersebut. Dalam hal ini, Tripwe tidak akan
              bertanggungjawab atas setiap kejadian penipuan kartu kredit atau
              penyalahgunaan atas kartu kredit Pengguna oleh pihak ketiga, tanpa
              mengesampingkan apakah kejadian penipuan atau penyalahgunaan
              tersebut dilakukan melalui Tripwe. Tripwe tidak berkewajiban untuk
              melakukan pengembalian pembayaran atau membayarkan kembali kepada
              anda sebagai akibat dari penipuan tersebut.
            </li>
            <li>
              Sebagai bagian dari itikad baik kami, kami dapat memberikan suatu
              bentuk kompensasi kepada anda berdasarkan kepada peninjauan dan
              persetujuan dari kami. Pengguna hanya akan berhak untuk meminta
              kompensasi apabila pemesanan tersebut dilakukan melalui server
              aman kami dan penipuan atau penyalahgunaan atas kartu kredit
              Pengguna merupakan akibat dari kesalahan atau kelalaian kami dan
              bukan akibat dari kesalahan anda sendiri ketika menggunakan server
              aman kami. Kami tidak bertanggungjawab atas penipuan atau
              penyalahgunaan atas kartu kredit Pengguna apabila hal tersebut
              dilakukan melalui Aplikasi Tripwe, Situs Tripwe, dan/atau server
              selain milik kami atau apabila hal tersebut merupakan akibat dari
              kesalahan atau kelalaian anda sendiri. Apabila Pengguna mencurigai
              adanya pemesanan tidak sah atau penipuan yang dilakukan pada
              Tripwe, anda harus segera menghubungi tim Customer Service kami
              yang tersedia di Aplikasi Triwpe dan/atau Situs Tripwe.
            </li>
          </ul>
        </div>
        <div class="flex flex-col gap-4">
          <p class="font-semibold md:text-2xl text-lg text-center">HARGA</p>
          <ul
            class="list-decimal text-justify text-sm md:text-lg text-grey-2 md:ml-10 ml-4"
          >
            <li>
              Harga Barang dan/atau Harga Jasa yang terdapat dalam Aplikasi
              Tripwe dan/atau Situs Tripwe adalah harga yang ditetapkan Vendor.
              Vendor dilarang memanipulasi harga dalam tujuan apapun.
            </li>
            <li>
              Vendor dilarang melakukan penetapan harga tidak wajar pada Barang
              dan/atau Jasa yang ditawarkan melalui Aplikasi Tripwe dan/atau
              Situs Tripwe.
            </li>
            <li>
              Pengguna memahami dan menyetujui bahwa setiap masalah dan/atau
              perselisihan yang terjadi akibat ketidaksepahaman antara Vendor
              dan Pengguna tentang harga bukanlah merupakan tanggung jawab
              Tripwe.
            </li>
            <li>
              Dengan melakukan pemesanan dan/atau pembelian melalui Tripwe,
              Pengguna menyepakati untuk membayar total biaya yang harus dibayar
              sebagaimana tertera dalam halaman pembayaran yang terdiri dari
              harga barang, ongkos kirim, dan biaya-biaya lainnya (apabila ada).
            </li>
          </ul>
        </div>
        <div class="flex flex-col gap-4">
          <p class="font-semibold md:text-2xl text-lg text-center">
            TARIF PENGIRIMAN
          </p>
          <p class="text-justify text-sm md:text-lg text-grey-2">
            Pengguna memahami dan mengerti bahwa Tripwe telah melakukan usaha
            sebaik mungkin dalam memberikan informasi tarif pengiriman kepada
            Pengguna berdasarkan lokasi yang akurat, namun Tripwe tidak dapat
            menjamin keakuratan data tersebut dengan yang ada pada lokasi
            setempat.
          </p>
          <ul
            class="list-decimal text-justify text-sm md:text-lg text-grey-2 md:ml-10 ml-4"
          >
            <li>
              Karena itu, Tripwe menyarankan kepada Vendor untuk mencatat
              terlebih dahulu tarif yang diberikan Tripwe, agar dapat
              dibandingkan dengan tarif yang dibebankan.
            </li>
            <li>
              Pengguna memahami dan menyetujui bahwa selisih biaya pengiriman
              Barang adalah diluar tanggung jawab Tripwe dan oleh karena itu,
              adalah kebijakan Vendor sendiri untuk membatalkan atau tetap
              melakukan pengiriman barang
            </li>
          </ul>
        </div>
        <div class="flex flex-col gap-4">
          <p class="font-semibold md:text-2xl text-lg text-center">
            PENGIRIMAN BARANG
          </p>
          <ul
            class="list-decimal text-justify text-sm md:text-lg text-grey-2 md:ml-10 ml-4"
          >
            <li>
              Pengiriman barang dalam sistem Tripwe wajib menggunakan jasa
              ekspedisi yang mendapatkan verifikasi rekanan Tripwe yang dipilih
              oleh Pengguna.
            </li>
            <li>
              Setiap ketentuan berkenaan dengan proses pengiriman Barang adalah
              wewenang penuh penyedia jasa ekspedisi.
            </li>
            <li>
              Pengguna memahami dan menyetujui bahwa setiap permasalahan yang
              terjadi pada saat proses pengiriman barang adalah tanggung jawab
              penyedia jasa ekspedisi.
            </li>
          </ul>
        </div>
        <div class="flex flex-col gap-4">
          <p class="font-semibold md:text-2xl text-lg text-center">
            PEMBATALAN
          </p>
          <ul
            class="list-decimal text-justify text-sm md:text-lg text-grey-2 md:ml-10 ml-4"
          >
            <li>
              Dengan membuat pemesanan, pesanan dan/atau pembelian melalui Situs
              Tripwe dan/atau Aplikasi Tripwe, Pengguna menerima dan setuju
              dengan syarat dan ketentuan dari Vendor yang berlaku, termasuk
              yang berkaitan dengan kebijakan tentang pembatalan dan/atau
              ketiadaan, atau sehubungan dengan permintaan khusus Anda yang
              mungkin diberikan kepada Vendor. Tripwe tidak bertanggung jawab
              atas setiap pelanggaran syarat dan ketentuan yang disepakati
              antara Vendor dan Pengguna, atau yang didasarkan pada permintaan
              khusus pengguna, sehingga harap membaca syarat dan ketentuan
              Vendor dengan cermat.
            </li>
            <li>
              Sehubungan dengan pembatalan pengguna yang telah dilakukan, Tripwe
              mungkin dapat menahan atau mengambil bagian dari jumlah yang
              dibayarkan untuk mengganti biaya yang telah dikeluarkan sehubungan
              dengan pembatalan.
            </li>
          </ul>
        </div>
        <div class="flex flex-col gap-4">
          <p class="font-semibold md:text-2xl text-lg text-center">
            BIAYA TAMBAHAN DAN PENGEMBALIAN DANA
          </p>
          <ul
            class="list-decimal text-justify text-sm md:text-lg text-grey-2 md:ml-10 ml-4"
          >
            <li>
              Setiap harga yang tercantum pada Aplikasi Tripwe dan/atau Situs
              Tripwe ini hanya tersedia dengan ketentuan tertentu dan harga
              tersebut dapat berubah tergantung pada ketersediaan pemesanan.
              Harga yang tersedia dapat mencakup pajak tambahan dan biaya
              lainnya lagi tapi dalam keadaan tertentu mungkin tidak termasuk
              pajak dan biaya jasa lainnya (seperti biaya tips untuk pemandu
              wisata, biaya fasilitas lainnya (jika ada), dan biaya-biaya
              lainnya yang timbul bukan dari penggunaan layanan kami); Pengguna
              setuju bahwa mereka bertanggung jawab untuk memverifikasi total
              biaya yang harus dibayar dan persyaratan lainnya dan rincian
              ketika email konfirmasi dikirim ke pengguna. Pengguna harus
              memverifikasi pemesanan dalam lembar pemesanan; Pengguna dapat
              membatalkan pemesanan setiap saat sebelum konfirmasi akhir
              dilakukan. Harga yang ditampilkan terperinci sehingga Pengguna
              dapat melihat jumlah yang harus dibayar, biaya tambahan apa pun
              yang disebabkan penggunaan kartu kredit atau biaya antar bank yang
              dikenakan untuk biaya pengiriman akan dibebankan kepada pengguna
              dan jika terdapat kekurangan dalam jumlah yang dibayarkan, maka
              Tripwe dapat memberikan notifikasi email mengenai kekurangan dalam
              jumlah yang harus dibayarkan pengguna. Dalam hal Pengguna
              membatalkan pemesanan sebelum membayar penuh segala kekurangan
              yang ada, Tripwe memiliki kebijaksanaan tunggal apakah akan
              mengembalikan jumlah yang dibayarkan atau mengembalikan jumlah
              yang telah dikurangi biaya yang dikeluarkan oleh Tripwe sebagai
              akibat dari biaya yang tidak dibayarkan atas kekurangan ini oleh
              Pengguna sesuai dengan prosedur yang ditetapkan oleh Tripwe. Jika
              Pengguna memiliki keraguan tentang layanan, Pengguna dapat
              menghubungi layanan pelanggan Tripwe (selama waktu operasional
              layanan pelanggan yang ditentukan oleh Tripwe). Untuk setiap
              pembatalan lainnya, sesuai dengan hukum yang berlaku, serta
              kebijakan Tripwe dan Vendor, Tripwe pertama-tama akan melakukan
              investigasi dan memverifikasi pesanan sebelum memberikan
              pengembalian dana sesuai dengan jumlah yang sudah Pengguna
              bayarkan, dikurangi biaya yang dikeluarkan oleh Tripwe, termasuk
              namun tidak terbatas pada biaya administratif, biaya transfer
              antar bank, biaya kartu kredit, dll. (“Jumlah Refund”). Setelah
              pesanan berhasil diverifikasi oleh Tripwe, pengembalian dana akan
              ditawarkan kepada Anda berupa voucher elektronik (mis. kupon
              diskon, Tripwe Pay, dan/atau tiket wisata dari Vendor).
            </li>
            <li>
              Tripwe tidak akan bertanggung jawab atau berkewajiban atas setiap
              penerbitan e-tiket, e-voucher, atau transaksi lainnya yang
              digugurkan akibat jumlah yang ditransfer tidak akurat oleh
              Pengguna atau pembayaran yang tidak dilakukan melalui metode
              pembayaran resmi yang tersedia di Situs Tripwe dan/atau Aplikasi
              Tripwe. Setiap transfer yang dilakukan melebihi batas waktu untuk
              transfer akan mengakibatkan pemesanan digugurkan. Untuk waktu
              nyata (real-time) transfer antar bank, Anda harus menggunakan ATM.
              Setelah menyelesaikan transfer antar bank, harap konfirmasi
              pembayaran Anda dengan mengisi formulir konfirmasi pembayaran.
            </li>
          </ul>
        </div>
        <div class="flex flex-col gap-4">
          <p class="font-semibold md:text-2xl text-lg text-center">
            HAK PENGGUNA
          </p>
          <p class="text-justify text-sm md:text-lg text-grey-2">
            Tripwe memberi Pengguna lisensi yang bersifat pribadi, di seluruh
            dunia, bebas royalti, tidak dapat dialihkan, noneksklusif, dapat
            dibatalkan, dan tidak dapat disublisensikan untuk mengakses serta
            menggunakan Layanan. Lisensi ini semata-mata ditujukan agar Pengguna
            dapat menggunakan dan menikmati manfaat Layanan ini sebagaimana
            dimaksudkan oleh Tripwe dan diizinkan oleh Perjanjian ini. Oleh
            karena itu, Pengguna setuju untuk tidak:
          </p>
          <ul
            class="list-decimal text-justify text-sm md:text-lg text-grey-2 md:ml-10 ml-4"
          >
            <li>
              menggunakan Layanan atau konten apa pun yang terdapat dalam
              Layanan demi tujuan komersial apa pun tanpa persetujuan tertulis
              dari kami.
            </li>
            <li>
              menyalin, mengubah, mengirimkan, membuat karya turunan,
              memanfaatkan, atau memperbanyak materi, gambar, merek dagang, nama
              dagang, merek layanan berhak cipta, atau kekayaan intelektual
              lainnya, konten, ataupun informasi paten yang dapat diakses
              melalui Layanan tanpa persetujuan tertulis sebelumnya dari Tripwe.
            </li>
            <li>
              menyatakan atau menyiratkan bahwa setiap pernyataan yang Pengguna
              buat didukung oleh Tripwe.
            </li>
            <li>
              menggunakan robot, bot (program otomatis), spider (pencari situs),
              crawler (perayap web), scraper (pengumpul data), aplikasi
              pencarian/pengambilan situs, proxy, ataupun perangkat, metode,
              atau proses manual maupun otomatis lainnya untuk mengakses,
              mengambil, mengindeks, “menambang data”, atau memperbanyak,
              mengakali struktur navigasi, ataupun tampilan Layanan maupun
              kontennya dengan cara apa pun.
            </li>
            <li>
              menggunakan Layanan dengan cara apa pun yang dapat menghalangi,
              mengganggu, atau memberikan dampak negatif terhadap Layanan,
              server, ataupun jaringan yang terhubung dengan Layanan.
            </li>
            <li>
              mengunggah virus atau kode berbahaya lainnya ataupun membahayakan
              keamanan Layanan.
            </li>
            <li>
              memalsukan atau memanipulasi pengidentifikasi guna menyamarkan
              asal informasi yang dikirimkan ke atau melalui Layanan.
            </li>
            <li>
              “menjiplak” atau “meniru” bagian Layanan mana pun tanpa izin
              tertulis sebelumnya dari Tripwe.
            </li>
            <li>
              memeriksa, memindai, atau menguji kerentanan Layanan kami atau
              setiap sistem atau jaringan.
            </li>
            <li>
              mendorong atau mendukung aktivitas apa pun yang melanggar
              Perjanjian ini
            </li>
          </ul>
        </div>
        <div class="flex flex-col gap-4">
          <p class="font-semibold md:text-2xl text-lg text-center">
            HAK ATAS KEKAYAAN INTELEKTUAL
          </p>
          <ul
            class="list-decimal text-justify text-sm md:text-lg text-grey-2 md:ml-10 ml-4"
          >
            <li>
              Kami adalah pemegang hak lisensi atas nama, ikon, dan logo
              ‘Tripwe’ yang mana merupakan hak cipta dan merek dagang yang
              dilindungi undang-undang Republik Indonesia. Pengguna tidak dapat
              menggunakan, memodifikasi, atau memasang nama, ikon, logo, atau
              merek tersebut tanpa persetujuan tertulis dari Kami.
            </li>
            <li>
              Kami dapat mengambil tindakan hukum terhadap setiap pelanggaran
              yang dilakukan oleh Pengguna terkait dengan hak kekayaan
              intelektual terkait Aplikasi.
            </li>
          </ul>
        </div>
        <div class="flex flex-col gap-4">
          <p class="font-semibold md:text-2xl text-lg text-center">
            KETENTUAN LAIN-LAIN
          </p>
          <ul
            class="list-decimal text-justify text-sm md:text-lg text-grey-2 md:ml-10 ml-4"
          >
            <li>
              Apabila Pengguna mempergunakan fitur-fitur yang tersedia dalam
              Aplikasi Tripwe dan/atau Situs Tripwe, maka Pengguna dengan ini
              menyatakan memahami dan menyetujui segala syarat dan ketentuan
              yang diatur khusus.
            </li>
            <li>
              Apabila Pengguna mempergunakan fitur-fitur yang tersedia dalam
              Aplikasi Tripwe dan/atau Situs Tripwe, maka Pengguna dengan ini
              menyatakan memahami dan menyetujui segala syarat dan ketentuan
              yang diatur khusus.
            </li>
          </ul>
        </div>
        <div class="flex flex-col gap-4">
          <p class="font-semibold md:text-2xl text-lg text-center">
            PENOLAKAN JAMINAN DAN BATASAN TANGGUNG JAWAB
          </p>
          <ul
            class="list-decimal text-justify text-sm md:text-lg text-grey-2 md:ml-10 ml-4"
          >
            <li>
              Tripwe selalu berupaya untuk menjaga layanan aplikasi dan situs
              Tripwe aman, nyaman, dan berfungsi dengan baik, tapi kami tidak
              dapat menjamin operasi terus menerus atau akses layanan kami
              selalu sempurna. Informasi dan data dalam aplikasi dan situs
              Tripwe memiliki kemungkinan tidak terjadi secara
              <span class="italic">real time</span>.
            </li>
            <li>
              Pengguna setuju bahwa Pengguna memanfaatkan layanan Tripwe atas
              segala risiko Pengguna dan layanan Tripwe diberikan kepada
              Pengguna berdasarkan pada kondisi “sebagai mana adanya” dan
              “sebagaimana tersedia”.
            </li>
            <li>
              Sejauh diizinkan oleh hukum yang berlaku, Tripwe tidak bertanggung
              jawab dan Pengguna setuju untuk tidak menuntut Tripwe atas segala
              kerusakan atau kerugian yang diakibatkan secara langsung atau
              tidak langsung dari:
              <ol
                class="list-decimal text-justify text-sm md:text-lg text-grey-2 md:ml-10 ml-4"
              >
                <li>
                  Penggunaan atau ketidakmampuan Pengguna dalam menggunakan
                  layanan Tripwe.
                </li>
                <li>
                  Harga, pengiriman atau petunjuk lain yang tersedia dalam
                  layanan Tripwe.
                </li>
                <li>Keterlambatan atau gangguan dalam layanan Tripwe.</li>
                <li>Kualitas barang.</li>
                <li>Kelalaian yang diakibatkan oleh Pengguna.</li>
                <li>Perselisihan antar Pengguna.</li>
                <li>Pelanggaran Hak atas Kekayaan Intelektual.</li>
                <li>Pencemaran nama baik.</li>
                <li>
                  Kerugian akibat pembayaran diluar metode yang ditetapkan oleh
                  Tripwe.
                </li>
                <li>
                  Virus atau perangkat lunak berbahaya lainnya yang diperoleh
                  dengan mengakses atau menghubungkan ke layanan Tripwe.
                </li>
                <li>
                  Virus atau perangkat lunak berbahaya lainnya yang diperoleh
                  dengan mengakses atau menghubungkan ke layanan Tripwe.
                </li>
                <li>
                  Adanya Tindakan peretasan yang dilakukan oleh pihak ketiga
                  kepada akun Pengguna.
                </li>
              </ol>
            </li>
          </ul>
        </div>
        <div class="flex flex-col gap-4">
          <p class="font-semibold md:text-2xl text-lg text-center">
            GANTI RUGI
          </p>
          <p class="text-justify text-sm md:text-lg text-grey-2">
            Pengguna akan melepaskan Tripwe dari ganti rugi dan menjaga Tripwe
            dari setiap klaim dan tuntutan termasuk biaya hukum yang wajar, yang
            dilakukan oleh pihak ketiga yang timbul dalam hal dimana Pengguna
            melanggar ketentuan dalam perjanjian ini serta penggunaan layanan
            Tripwe yang tidak semestinya.an yang dilakukan oleh pihak ketiga
            kepada akun Pengguna.
          </p>
        </div>
        <div class="flex flex-col gap-4">
          <p class="font-semibold md:text-2xl text-lg text-center">
            PEMBAHARUAN
          </p>
          <p class="text-justify text-sm md:text-lg text-grey-2">
            Syarat & Ketentuan mungkin diubah dan/atau diperbaharui dari waktu
            ke waktu tanpa pemberitahuan sebelumnya. Tripwe menyarankan agar
            Pengguna membaca secara seksama dan memeriksa halaman Syarat &
            Ketentuan ini dari waktu ke waktu untuk mengetahui perubahan apapun.
            Dengan tetap mengakses layanan Tripwe, maka pengguna dianggap
            menyetujui perubahan dalam Syarat & Ketentuan.
          </p>
        </div>
        <div class="flex flex-col gap-4">
          <p class="font-semibold md:text-2xl text-lg text-center">
            PILIHAN HUKUM
          </p>
          <p class="text-justify text-sm md:text-lg text-grey-2">
            Perjanjian ini diatur dan dibuat berdasarkan hukum di Republik
            Indonesia. Pengguna setuju bahwa apabila timbul sengketa atas
            perjanjian ini maupun akibat dari layanan kami, maka penyelesaian
            sengketa dilakukan secara musyawarah mufakat. Apabila tidak dapat
            diselesaikan, sengketa dapat diselesaikan secara eksklusif dalam
            yuridiksi pengadilan Republik Indonesia.
          </p>
        </div>
        <div class="flex flex-col gap-4">
          <p class="font-semibold md:text-2xl text-lg text-center">
            KEADAAN KAHAR
          </p>
          <p class="text-justify text-sm md:text-lg text-grey-2">
            Tripwe tidak bertanggung jawab atas pelanggaran yang terjadi
            terhadap perjanjian ini terkait kerusakan sistem yang diluar dugaan.
            Keadaan kahar termasuk namun tidak terbatas pada bencana alam
            (banjir, gempa bumi, kebakaran), terorisme, pandemi, epidemi,
            embargo, pergantian peraturan perundang-undangan, dan serangan
            siber.
          </p>
        </div>

        <div class="flex flex-col gap-4">
          <p class="font-semibold md:text-2xl text-lg text-center">UMUM</p>
          <ul
            class="list-decimal text-justify text-sm md:text-lg text-grey-2 md:ml-10 ml-4"
          >
            <li>
              Perjanjian Pengguna ini akan mengatur seluruh perjanjian dan
              pemahaman antara Pengguna dan Tripwe pada pokok daripadanya, dan
              akan menggantikan semua perjanjian sebelumnya, baik tertulis atau
              lisan, antara Pengguna dan Tripwe tentang pokok daripadanya.
            </li>
            <li>
              Untuk menghindari keraguan, Tripwe tidak mengelola atau mengontrol
              Vendor
            </li>
            <li>
              Perjanjian Pengguna ini dibuat dalam Bahasa Indonesia dan Bahasa
              Inggris dan telah sesuai dengan ketentuan perundang-undangan
              mengenai penggunaan Bahasa Indonesia. Dalam hal terdapat perbedaan
              terjemahan, maka versi Bahasa Indonesia akan berlaku, dan versi
              Bahasa Inggris wajib disesuaikan dengan versi Bahasa Indonesia.
            </li>
            <li>
              Dalam hal bahwa ketentuan apa pun ditetapkan untuk tidak dapat
              dilaksanakan atau tidak sah, oleh pengadilan dari yurisdiksi yang
              kompeten, maka ketentuan tersebut akan tetap diberlakukan sejauh
              diizinkan oleh hukum yang berlaku dan ketetapan tersebut tidak
              akan mempengaruhi validitas dan keberlakuan dari setiap ketentuan
              lainnya yang tersisa. Ketentuan yang terputus harus digantikan
              dengan ketentuan yang mendekati kata-kata asli dan maksud sebanyak
              mungkin.
            </li>
            <li>
              Kegagalan salah satu Pihak untuk melaksanakan setiap ketentuan
              dari Syarat ini kapan saja tidak akan ditafsirkan sebagai
              pelepasan hak masing-masing Pihak untuk melaksanakan pelanggaran
              ketentuan tersebut atau setiap ketentuan lainnya dalam Syarat ini
              atau sebagai pelepasan dari pelanggaran yang terus-menerus, yang
              berikut atau yang menyusul atas setiap ketentuan atau ketentuan
              lainnya dari Perjanjian Pengguna ini.
            </li>
            <li>
              Anda tidak dapat menyerahkan atau mengalihkan hak atau kewajiban
              Anda menurut Syarat ini, tanpa persetujuan tertulis kami
              sebelumnya
            </li>
          </ul>
        </div>
        <div class="flex flex-col gap-4">
          <p class="font-semibold md:text-2xl text-lg text-center">FAQs</p>
          <p class="text-center text-sm md:text-lg text-grey-2">
            Jika Anda memiliki pertanyaan lebih lanjut, harap merujuk pada
            <strong>FAQs.</strong>
          </p>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>
<script>
import Header from "../components/Header.vue";
import Footer from "../components/Footer.vue";

export default {
  components: { Header, Footer },
  data: () => ({}),
};
</script>
